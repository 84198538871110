@import "../../global.scss";

.contact {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: 60px;
    margin-top: 30px;
    span{
      color: $mainColor;
      font-weight: 700;
    }

    @include mobile{
      font-size: 45px;
    }
  }

  .logo-container{
    width: 65%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 300px;
    align-items: center;
    text-align: center;

    @include mobile{
      flex-direction: column;
    }

    a{
      width: 250px;
      height: 250px;
      margin-top: 30px;
      @include mobile{
        margin-top: 10px;
        width: 150px;
        height: 150px;
      }

    }

    img{
      
      width: 180px;
      height: 180px;
      transition: transform .2s;
      padding:0;
      margin-top:20px;
      @include mobile{
        margin-top: 10px;
        width: 80px;
        height: 80px;
      }
    }

    img:hover{
      opacity: 0.55;
      cursor: pointer;
      transform: scale(1.1);
    }
  }
  h2{
    margin-top: 30px;
    span{
      color: $mainColor;
      font-weight: 700;
    }
  }
  .file-container{
    width: 65%;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    height: 30%;
    align-items: center;
    text-align: center;

    img{
      
      margin-right: 30px;
      margin-left: 40px;
      width: 180px;
      height: 180px;
      transition: transform .2s;
      margin-top:50px;

      @include mobile{
        margin-top: 20px;
        width: 80px;
        height: 80px;
      }
    }

    img:hover{
      opacity: 0.55;
      cursor: pointer;
      transform: scale(1.1);
    }
    
  }
  hr {
    width: 35%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    background-color: $mainColor;
    color: $mainColor;
  }
}