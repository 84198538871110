@import "../../global.scss";

.aboutme{
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  .abtm-title-ctn{
    display: flex;
    flex-direction: row;

    h1 {
      font-size: 60px;
      margin-top: 30px;
      margin-right: 20px;

      @include mobile{
        font-size: 45px;
      }

    }
    .me-text{
      color: $mainColor
    }
    
  }
  .aboutme-info{
    margin-top: 70px;
    padding: 0 20%;
    font-size: 1.85rem;
    letter-spacing: 1px;
    font-weight: 400;
    line-height: 37px;

    @include mobile{
      font-size: 1rem;
      padding: 0 10%;
      line-height: 25px;
      margin-top: 10px;
      line-height: 21px;
      letter-spacing: 0.65px;
    }

    span{
      color: $mainColor;
      font-weight: 700;
    }
  }

  
}


h2{
  margin-top: 8%;
  font-size: 3rem;

  @include mobile{
    font-size: 35px;
  }
}

.skills-container-1{
  display: flex;
  flex-direction: row;
  width: 55%;
  justify-content:space-between;
  margin-top:30px;
  font-size: 1.2rem;
  // padding: 0 60%;

  @include mobile{
    margin-top: 20px;
    font-size: 0.85rem;
    width:75%;
  }
}

.skill-detail:hover{
  color:$mainColor;
  cursor: pointer;
}
.skills-container-2{
  display: flex;
  flex-direction: row;
  width: 55%;
  justify-content:space-between;
  margin-top:20px;
  font-size: 1.2rem;

  @include mobile{
    margin-top: 20px;
    font-size: 0.85rem;
    width:75%;
  }
}

hr {
  width: 55%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  background-color: $mainColor;
  color: $mainColor;

  @include mobile{
    width: 75%;
  }
}