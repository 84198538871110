@import "../../global.scss";

.intro{
  background-color: white;
  display: flex;

  @include mobile{
    flex-direction: column;
    align-items: center;
  }

  .left{
    flex: 0.5;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    @include mobile{
      width: 90%;
      margin-top: 20%;
    }

    .imgContainer{
      width: 900px;
      height: 900px;
      background: linear-gradient(0, rgb(77, 102, 28) 0%, rgb(180, 213, 115) 100%);

      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      float:right;

      @include mobile{
        width: 90%;
        height: 90%;
        align-items: center;
        margin: 0 auto;
      }

    }
    
    @include mobile{
      align-items: flex-start;
    }
    

    img{
      
      @include mobile{
        height: 100%;
      }
    }
  }
  .right{
    flex: 0.5;
    position: relative;

    .wrapper{
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @include mobile{
        padding-left: 0;
        align-items: center;
        justify-content: flex-start;
        margin-top: 20px;
        max-height: 50%;
      }

      h1{
        font-size: 70px;
        margin: 10px 0;

        @include mobile{
          font-size: 40px;
        }
      }
      h2{
        font-size: 35px;
      }
      h3{
        font-size: 30px;

        @include mobile{
          font-size: 20px;
        }

        span{
          font-size: inherit;
          color: olivedrab;
        }

        .ityped-cursor {
          animation: blink 1s infinite;
        }

        @keyframes blink {
          50%{
              opacity: 1;
          }
          100%{
              opacity: 0;
          }
      }
      }
    }
    a{
      position: absolute;
      bottom:10px;

      @include mobile{
        position:bottom;
      }

      img{
        width: 50px;
        animation: arrowBlink 2s infinite;

        @include mobile{
          display:none;
        }
      }

      @keyframes arrowBlink{
        100%{
          opacity: 0;
        }
      }
    }
  }
}