@import "../../global.scss";



.portfolio {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: 60px;
    margin-top: 30px;

    @include mobile{
      font-size: 45px;
    }
  }

  ul {
    margin: 10px;
    padding: 0;
    list-style: none;
    display: flex;

    @include mobile{
      margin: 10px 0;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  .container {
    width: 70%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 20px;

    

    @include mobile{
      width: 100%;
      margin-top:20px;
    }

    .item {
      width: 470px;
      height: 370px;
      border-radius: 20px;
      border: 1px solid rgb(240, 239, 239);
      margin: 10px 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      position: relative;
      transition: all .7s ease;
      cursor: pointer;

      @include mobile{
        width: 230px;
        height: 200px;
      }

      .info-container{
      display:flex;
      flex-direction: column;
    }

      h3 {
        position: absolute;
        font-size: 23px;
        font-weight: 500;
        padding: 0 40px;

        @include mobile{
          font-size:15px;
        }
        

        span{
          font-weight: 300;
          font-size: 16px;

          @include mobile{
            font-size:10px;
          }
        }
        span.titlespan{
          font-size: 50px;
          font-weight: 700;

          @include mobile{
            font-size:35px;
          }
        }
        
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;
      }

      &:hover {
        background-color: $mainColor;
        img {
          opacity: 0.08;
          z-index: 0;
        }
      }
    }
  }
}

.src-code{
  background-color: white;
  color:$mainColor;
  text-decoration: none;
}
.src-code:hover{
  background-color: rgb(65, 81, 37);
  color:white;
}